import request from '@/utils/requestV2'
const qs = require('qs')

// 新增媒介主的刊前付款比例折扣
export function addPayDiscount (data) {
  return request({
    url: '/ooh-scp/v1/paydiscount/addpaydiscount',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 删除媒介主的刊前付款比例折扣
export function deletePayDiscount (data) {
  return request({
    url: '/ooh-scp/v1/paydiscount/deletepaydiscount',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取媒介主下的刊前付款比例折扣和天数比例列表
export function getPayDiscountList (data) {
  return request({
    url: '/ooh-scp/v1/paydiscount/getpaydiscountlist',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 根据id获取刊前付款比例折扣
export function getPayDiscount (data) {
  return request({
    url: '/ooh-scp/v1/paydiscount/getpaydiscount',
    method: 'post',
    data: qs.stringify(data)
  })
}

export function getAllPayDiscount (data) {
  return request({
    url: '/ooh-scp/v1/paydiscount/getallpaydiscount',
    method: 'post',
    data: qs.stringify(data)
  })
}
