import request from '@/utils/requestV2'
const qs = require('qs')

// 查看结算单订单详情(分类) (按订单=》档期=》产品)
export function getOrderScheduleCategoryList (data) {
  return request({
    url: '/ooh-scp/v1/statementorder/getorderschedulecategorylist',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取当前审过的结算信息(合并了调整项的)
export function getmergedorderschedulecategorylist (data) {
  return request({
    url: '/ooh-scp/v1/statementorder/getmergedorderschedulecategorylist',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取订单的结算价信息
export function getorderplanprice (data) {
  return request({
    url: '/ooh-scp/v1/statementorder/getorderplanprice',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取结算单中订单的结算价信息
export function getOrderSettlementPrice (data) {
  return request({
    url: '/ooh-scp/v1/statementorder/getOrderSettlementPrice',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 更改结算单明细数据的服务费结算价
export function updateOrderSettlementPrice (data) {
  return request({
    url: '/ooh-scp/v1/statementorder/updateOrderSettlementPrice',
    method: 'post',
    data: qs.stringify(data)
  })
}
