import request from '@/utils/requestV2'
const qs = require('qs')

// 变更结算单
export function changeStatement (data) {
  return request({
    url: '/ooh-scp/v1/statementchange/changestatement',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 删除变更（仅草稿状态可删除）
export function deleteVersion (data) {
  return request({
    url: '/ooh-scp/v1/statementchange/deleteversion',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 条件查询所有结算单的结算单变更列表，分页显示
export function getStatementChangePage (data) {
  return request({
    url: '/ooh-scp/v1/statementchange/getstatementchangepage',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取到结算单变更版本标识（若没有，会创建一个返回。若存在当前进行中的变更，则返回当前进行中的变更标识id。）
export function getStatementChangingVersionId (data) {
  return request({
    url: '/ooh-scp/v1/statementchange/getstatementchangingversionid',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 查看某次结算单变更详情
export function getVersionDetail (data) {
  return request({
    url: '/ooh-scp/v1/statementchange/getversiondetail',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取特定结算单所有变更记录
export function getVersionList (data) {
  return request({
    url: '/ooh-scp/v1/statementhange/getversionlist',
    method: 'post',
    data: qs.stringify(data)
  })
}
