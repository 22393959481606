<template>
  <div>
    <!-- 付款清单列表  -->
    <i-table stripe  :columns="titles" :data="items" size="small"></i-table>
  </div>
</template>

<script>

import { ParseDate } from '@/utils/dateFormat'
import { toMoney } from '@/utils/wnumb_own'

export default {
  props: {
    items: {
      type: Array
    }
  },
  data () {
    return {
      titles: [
        {
          title: '付款截至日期',
          render: (h, data) => {
            return h('span', ParseDate(data.row.date))
          }
        },
        {
          title: '付款金额',
          render: (h, data) => {
            return h('span', { class: 'money' }, toMoney(data.row.money))
          }
        }
      ]
    }
  },
  watch: {
    items (val) {
      if (val.length !== 0) {
        if ((Math.round(new Date().getTime()) - val[0].date) / 86400000 > 0) { this.$emit('checkDate', true) } else {
          this.$emit('checkDate', false)
        }
      }
    }
  }
}
</script>
