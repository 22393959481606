<template>
  <div>
    <i-table stripe :columns="columns" :data="products"></i-table>
    <Modal v-model="showModal" :width="400" :mask-closable="false">
      <p slot="header" class="workplatform-title">
        <span>调整结算价</span>
      </p>
      <InputNumber
        v-model="params.normalSettlementPrice"
        :min="0"
        style="width: 100%"
      ></InputNumber>
      <!-- <Row :gutter="8">
        <i-col span="8">
          <p>发布费</p>
          <InputNumber v-model="params.normalSettlementPrice"  :min="0" style="width:100%"></InputNumber></i-col>
        <i-col span="8">
          <p>制作费</p>
          <InputNumber v-model="params.totalProduceSettlePrice"  :min="0" style="width:100%"></InputNumber></i-col>
        <i-col span="8">
          <p>上下刊费</p>
          <InputNumber v-model="params.totalInstallSettlePrice" style="width:100%" :min="0"></InputNumber>
        </i-col>
      </Row> -->

      <div slot="footer">
        <i-button class="btn-black" @click="showModal = false">关闭</i-button>
        <i-button type="primary" @click="confirmChange">确认</i-button>
      </div>
    </Modal>
    <Modal
      v-model="showServiceSettingModal"
      :width="800"
      :mask-closable="false"
    >
      <p slot="header" class="workplatform-title">
        <span>调整服务费</span>
      </p>
      <Row class="text-bold">
      <i-col span="4">服务项名称</i-col>
      <i-col span="4">服务项类型</i-col>
      <i-col span="4">数量</i-col>
      <i-col span="4">刊例价</i-col>
      <i-col span="4">采购量</i-col>
      <i-col span="4">签约额</i-col>
    </Row>
    <div style="margin-top: 15px" v-for="(sc, i) in services" :key="i">
      <Row>
        <i-col span="4">
          {{ sc.serviceItemName }}
        </i-col>
        <i-col span="4">
          {{ sc.typeName }}
        </i-col>
        <i-col span="4"> {{ sc.serviceQuantity }} {{ sc.serviceQuantityName }} </i-col>
        <i-col span="4"> {{ formatMoney(sc.price) }} / {{ sc.serviceQuantityName }} </i-col>
        <i-col span="4">
          <!--  总数量 -->
          <InputNumber
            v-model="sc.signServiceQuantity"
            :min="0"
            size="small"
          ></InputNumber>
          <!-- <template v-if="product.normalSellPrice>0">
                <br/>
                <span class="remark">折扣：</span> <span class="money">{{computedSale(product.normalUsePrice ,product.normalSellPrice)}} 折</span>
              </template> -->
        </i-col>
        <i-col span="4">
          <!--  签约价 -->
          <InputNumber
            v-model="sc.signServiceAmount"
            :min="0"
            size="small"
          ></InputNumber>
        </i-col>
      </Row>
    </div>

      <div slot="footer">
        <i-button class="btn-black" @click="showServiceSettingModal = false">关闭</i-button>
        <i-button type="primary" @click="confirmServiceSetting">确认</i-button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { toMoney } from '@/utils/wnumb_own'

import { updateOrderSettlementPrice } from '@/api/scp/statementorder'
import { saveGoodServiceFee, getOrderGoodServiceFee } from '@/api/scp/contractorder'
export default {
  props: {
    versionId: {
      type: Number,
      required: true
    },
    products: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      columns: [],
      showModal: false,
      params: {
        statementDetailId: 0,
        normalSettlementPrice: 0,
        totalProduceSettlePrice: 0,
        totalInstallSettlePrice: 0
      },
      showServiceSettingModal: false,
      services: []
    }
  },
  created () {
    this.columns = [
      { title: '产品名称', key: 'productName' },
      {
        title: '采购数量',
        render: (h, data) => {
          return h('span', data.row.signNormalQuantity)
        }
      },
      // {
      //   title: '发布总量',
      //   render: (h, data) => {
      //     return h('span', data.row.signNormalLength * data.row.signNormalQuantity)
      //   }
      // },
      {
        title: '订单价',
        render: (h, data) => {
          return h('div', [
            h('span', { class: 'money' }, toMoney(data.row.normalUsePrice)),
            h('span', '(' + data.row.usePriceDiscount + ')')
          ])
        }
      },
      {
        title: '结算价',
        render: (h, data) => {
          return h('div', [
            h(
              'span',
              { attrs: { class: 'money' } },
              toMoney(data.row.normalSettlementPrice)
            ),
            h(
              'span',
              { attrs: { class: 'discount' } },
              '(' + this.computedSale(data.row.normalUsePrice, data.row.normalSettlementPrice) + '折 )'
            )
          ])
        }
      },
      {
        title: '服务费刊例价',
        render: (h, data) => {
          return h(
            'span',
            { attrs: { class: 'money' } },
            toMoney(data.row.totalServicePrice)
          )
        }
      },
      {
        title: '服务费签约价',
        render: (h, data) => {
          return h(
            'span',
            { attrs: { class: 'money' } },
            toMoney(data.row.signServicePrice)
          )
        }
      },
      {
        title: '操作',
        render: (h, data) => {
          const detailButton = h(
            'a',
            {
              style: { 'margin-left': '5px' },
              on: {
                click: () => {
                  this.showDetail(data.row)
                }
              }
            },
            '调整结算价'
          )
          const editServiceButton = h(
            'a',
            {
              style: { 'margin-left': '5px' },
              on: {
                click: () => {
                  this.showServiceSetting(data.row)
                }
              }
            },
            '服务费设置'
          )
          const returnBtn = [
            this.allowEdit ? detailButton : '',
            editServiceButton
          ]
          return h('div', returnBtn)
        }
      }
    ]
  },
  computed: {
    allowEdit: function () {
      // 允许输入 执行价低于最低折扣
      return this.$store.getters.token.publisherSetting.statementPriceEditable
    }
  },
  methods: {
    formatMoney (number) {
      return toMoney(number)
    },
    computedSale (usePrice, sellPrice) {
      if (usePrice > 0) {
        const discount = (sellPrice / usePrice) * 10
        const showDisCount = discount.toFixed(2)
        return '(' + showDisCount + '折)'
      } else {
        return ''
      }
      // return Number((sellPrice * 10 / usePrice).toFixed(2))
    },
    showDetail (record) {
      this.params.statementDetailId = record.statementDetailId
      this.params.normalSettlementPrice = record.normalSettlementPrice
      // this.params.totalInstallSettlePrice = record.totalInstallSettlePrice
      // this.params.totalProduceSettlePrice = record.totalProduceSettlePrice
      this.showModal = true
    },
    confirmChange () {
      const that = this
      updateOrderSettlementPrice({
        statementDetailId: that.params.statementDetailId,
        normalSettlementPrice: that.params.normalSettlementPrice
      }).then((res) => {
        that.showModal = false
        that.$emit('reloadProducts')
      })
    },
    showServiceSetting (product) {
      this.orderId = product.orderId
      getOrderGoodServiceFee({
        orderId: product.orderId,
        goodUseFeeId: product.goodUseFeeId
      }).then((res) => {
        this.services = res
        this.showServiceSettingModal = true
      })
    },
    confirmServiceSetting () {
      saveGoodServiceFee({
        orderId: this.orderId,
        versionId: this.versionId,

        serviceFees: JSON.stringify(this.services.map(item => {
          return {
            goodServiceFeeId: item.goodServiceFeeId, signServiceNum: item.signServiceQuantity, signServiceAmount: item.signServiceAmount
          }
        }))
      }).then((res) => {
        this.showServiceSettingModal = false
        this.$emit('reloadProducts')
      })
    }

  }
}
</script>
